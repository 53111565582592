@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Outfit";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding-top: 0px !important;
}
.PhoneInputInput {
  border: none;
  background-color: #fafafa;
  padding: 8px;
  margin-left: 4px;
  font-family: Outfit;
  font-size: "18px !important";
  border-radius: 8px;
  color: #898989;
  outline: none;
  height: "16px !important";
}

.PhoneInputCountry {
  background-color: #fafafa;
  padding: 12px;
  border-radius: 8px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  display: flex !important;
}

.outfit-font {
  font-family: "Outfit !important", sans-serif;
  font-optical-sizing: auto;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: "#097E52";
}

.carousel-root {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .carousel-root {
    width: 90vw;
  }
}